import _ from 'underscore';
import Timer from "./WidgetValues/Timer"
import Barcode from "./WidgetValues/Barcode";
import Button from "./WidgetValues/Button";
import CheckboxList from "./WidgetValues/CheckboxList";
import Dates from "./WidgetValues/DatePicker";
import Dropdown from "./WidgetValues/Dropdown";
import Image from "./WidgetValues/Image";
import FileUpload from "./WidgetValues/FileUpload";
import FlipSwitch from "./WidgetValues/FlipSwitch";
import Layout from "./WidgetValues/Layout";
import Map from "./WidgetValues/Map";
import Rating from "./WidgetValues/Rating";
import RadioButtonList from "./WidgetValues/RadiobuttonList";
import Section from "./WidgetValues/Section";
import Textbox from "./WidgetValues/Textbox";
import Hamburger from "./WidgetValues/Hamburger";
import ExternalData from "./WidgetValues/ExternalData";
import UserList from './WidgetValues/UserList';
import GroupList from './WidgetValues/GroupList';
import GetScannedValues from './WidgetValues/Barcode/GetScannedValues';
import SetButtonDisplayText from './WidgetValues/Button/SetButtonDisplayText';
import SetCheckboxlistOption from "./WidgetValues/CheckboxList/SetCheckboxlistOption";
import InCheckboxlistCheckboxIsChecked from "./WidgetValues/CheckboxList/InCheckboxlistCheckboxIsChecked";
import GetCheckboxlistSelectedCheckboxes from "./WidgetValues/CheckboxList/GetCheckboxlistSelectedCheckboxes";
import GetDateValue from "./WidgetValues/DatePicker/GetDateValue";
import SetDateValue from "./WidgetValues/DatePicker/SetDateValue";
import SetDatePlaceholderText from "./WidgetValues/DatePicker/SetDatePlaceholderText";
import SetDropdown from "./WidgetValues/Dropdown/SetDropdown";
import AddDropdownOption from "./WidgetValues/Dropdown/AddDropdownOption";
import GetSelectedValueOfDropdown from "./WidgetValues/Dropdown/GetSelectedValueOfDropdown";
import DropdownIsSetTo from "./WidgetValues/Dropdown/DropdownIsSetTo";
import SetLayoutBackgroundColour from "./WidgetValues/Layout/SetLayoutBackgroundColour";
import AddMarker from "./WidgetValues/Map/AddMarker";
import RemoveMarker from "./WidgetValues/Map/RemoveMarker";
import SetMapZoom from "./WidgetValues/Map/SetMapZoom";
import SetRating from "./WidgetValues/Rating/SetRating";
import GetRating from "./WidgetValues/Rating/GetRating";
import SetRadiobuttonList from "./WidgetValues/RadiobuttonList/SetRadiobuttonList";
import GetValueOfSelectedRadiobutton from "./WidgetValues/RadiobuttonList/GetValueOfSelectedRadiobutton";
import RadiobuttonListIsSetTo from "./WidgetValues/RadiobuttonList/RadiobuttonListIsSetTo";
import SetSectionBackgroundColour from "./WidgetValues/Section/SetSectionBackgroundColour";
import GetImageSource from "./WidgetValues/Image/GetImageSource";
import SetImageSource from './WidgetValues/Image/SetImageSource';
import ClearFileUpload from "./WidgetValues/FileUpload/ClearFileUpload";
import GetUploadedValue from './WidgetValues/FileUpload/GetUploadedValue';
import GetUploadedValues from './WidgetValues/FileUpload/GetUploadedValues';
import GetTextboxValue from "./WidgetValues/Textbox/GetTextboxValue";
import SetTextboxValue from "./WidgetValues/Textbox/SetTextboxValue";
import SetTextboxColour from "./WidgetValues/Textbox/SetTextboxColour";
import SetTextboxPlaceholderText from "./WidgetValues/Textbox/SetTextboxPlaceholderText";
import GetFlipSwitchValue from './WidgetValues/FlipSwitch/GetFlipSwitchValue';
import SetFlipSwitchValue from './WidgetValues/FlipSwitch/SetFlipSwitchValue';
import GetHamburgerName from './WidgetValues/Hamburger/GetHamburgerName';
import SetHamburgerName from './WidgetValues/Hamburger/SetHamburgerName';
import GetHamburgerImage from './WidgetValues/Hamburger/GetHamburgerImage';
import SetHamburgerImage from './WidgetValues/Hamburger/SetHamburgerImage';
import User from './WidgetValues/User';
import GetUserLoggedIn from './WidgetValues/User/GetUserLoggedIn';
import GetUserField from './WidgetValues/User/GetUserField';
import GetUserId from './WidgetValues/User/GetUserId';
import GetUserFirstName from './WidgetValues/User/GetUserFirstName';
import GetUserLastName from './WidgetValues/User/GetUserLastName';
import GetUserFullName from './WidgetValues/User/GetUserFullName';
import GetUserEmail from './WidgetValues/User/GetUserEmail';
import GetUserSystemId from './WidgetValues/User/GetUserSystemId';
import GetUserList from './WidgetValues/UserList/GetUserList';
import GetGroupList from './WidgetValues/GroupList/GetGroupList';
import SnapActionNavigate from "./AppActions/SnapActionNavigate";
import SnapActionNavigateBack from "./AppActions/NavigateBack";
import SnapActionResetPage from "./AppActions/ResetPage";
import SnapActionSubmit from './AppActions/Submit';
import SnapActionSync from './AppActions/Sync';
import SnapActionValidate from './AppActions/ValidatePage';
import SetFocus from './AppActions/SetFocus';
import ReloadCurrentPage from './AppActions/ReloadCurrentPage';
import ReloadPage from './AppActions/ReloadPage';
import OpenApp from './AppActions/OpenApp';
import OpenSnapApp from "./AppActions/OpenSnapApp";

// import SimpleRestService from "./Connections/SimpleRestService";
// import RestService from "./Connections/RestService";
import Log from "./Debug/Log";
import LogInfo from './Debug/LogInfo';
import LogError from './Debug/LogError';
import TryCatch from "./Debug/TryCatch";
import DateBlock from "./Dates/DateBlock";
import Today from './Dates/Today'
import GetDayFromDate from "./Dates/GetDayFromDate";
import AddToDate from "./Dates/AddToDate";
import DatePickerNumber from "./Dates/DatePickerNumber";
import DateIsBefore from "./Dates/DateIsBefore";
import ColourRGB from "./Colour/ColourRGB";
import ColourPicker from "./Colour/ColourPicker";
import {
    ListsCreateWith,
    ListsGetIndex, ListsGetSublist,
    ListsIndexOf,
    ListsIsEmpty,
    ListsLength,
    ListsRepeat, ListsSort, ListsSplit
} from "./Lists/BlocklyBlocks";
import ListsSetIndex2 from "./Lists/ListsSetIndex2";
import MakeJSONWith from "./Lists/MakeJSONWith";
import GetFromJSON from "./Lists/GetFromJSON";
import UpdateJSON from "./Lists/UpdateJSON";

import {
    Text,
    TextJoin,
    TextAppend,
    TextLength,
    // SOTISnackbar,
    TextIsEmpty,
    TextIndexOf,
    TextCharAt,
    TextGetSubstring,
    TextChangeCase,
    TextTrim,
    // TextPrint,
    // TextPromptExt,
} from './Text/BlocklyBlocks'
import ControlsIf from './Logic/ControlsIf';
import LogicBoolean from './Logic/LogicBoolean';
import LogicCompareNew from './Logic/LogicCompareNew';
import LogicNegate from './Logic/LogicNegate';
import LogicNull from './Logic/LogicNull';
import LogicOperation from './Logic/LogicOperation';
import SnapWait from './Logic/SnapWait';
import ControlsFlowStatements from './Loops/ControlsFlowStatements'
import ControlsRepeat from './Loops/ControlsRepeat'
import ControlsWhileUntil from './Loops/ControlsWhileUntil'
import CountWith from './Loops/CountWith'
import ForEachItemInList from './Loops/ForEachItemInList'
import {
    MathNumber,
    MathArithmetic,
    MathSingle,
    MathTrig,
    MathConstant,
    MathNumberProperty,
    MathRound,
    MathModulo,
    MathConstrain,
    MathRandomInt,
    MathRandomFloat,
    MathOnList
} from './Math/MathBlocks'
import RoundToDecimal from './Math/RoundToDecimal';
import MathToNumber from './Math/MathToNumber';
import { getAllPagesFromContext, getAllTypeWidgetsFromContext, getAllVariablesFromContext, getAllXSightTopicsFromContext } from "./helpers";

import Label from "./WidgetValues/Label";
import SetLabelText from "./WidgetValues/Label/SetLabelText";
import GetLabelText from "./WidgetValues/Label/GetLabelText";

import Paragraph from "./WidgetValues/Paragraph";
import SetParagraphText from "./WidgetValues/Paragraph/SetParagraphText";
import GetParagraphText from "./WidgetValues/Paragraph/GetParagraphText";
import SetParagraphColour from "./WidgetValues/Paragraph/SetParagraphColour";

import GetExternalDataColumn from './WidgetValues/ExternalData/GetExternalDataColumn';

import SotiSnackbar from "./Text/SotiSnackbar";
import GetEnvironmentVariable from "./Variables/GetEnvironmentVariable";
import SetEnvironmentVariable from "./Variables/SetEnvironmentVariable";
import GetXSightTopic from "./XSightTopics/GetXSightTopic";
import SetXSightTopic from "./XSightTopics/SetXSightTopic";

import RestService from './Connections/RestService';
import ShowMessage from './Text/ShowMessage';
import ShowPrompt from './Text/ShowPrompt';
import Confirm from './Text/Confirm';
import TextRegex from './Text/TextRegex';
import LoadTableDataRest from './TableView/LoadTableDataRest';
import AddRow from './TableView/AddRow';
import UpdateRow from './TableView/UpdateRow'
import GetRecords from './TableView/GetRecords';
import SetColumnName from './TableView/SetColumnName';
import GetColumn from './TableView/GetColumn';
import SelectedRow from './TableView/SelectedRow';
import DeleteRow from './TableView/DeleteRow';
import RefreshTable from './TableView/RefreshTable';
import RunEditRecords from './TableView/RunEditRecords';
import CancelEditRecords from './TableView/CancelEditRecords';
import SnapActionOpenBrowser from './AppActions/OpenBrowser';
import ShowHideRow from './TableView/ShowHideRow';

import MobiControlGetIpAddress from './MobiControl/GetIpAddress';
import MobiControlGetSerialNumber from './MobiControl/GetSerialNumber';
import MobiControlGetMacAddress from './MobiControl/GetMacAddress';
import MobiControlGetDeviceId from './MobiControl/GetDeviceId';
import MobiControlGetIsEnrolled from './MobiControl/GetIsEnrolled';
import MobiControlGetAndroidDeviceDetails from './MobiControl/GetAndroidDeviceDetails';
import MobiControlGetIosDeviceDetails from './MobiControl/GetIosDeviceDetails';
import MobiControlGetCustomAttribute from './MobiControl/GetCustomAttribute';

import PlayNote from './Sound/PlayNote';
import PlayBeep from './Sound/PlayBeeps';

import RefreshChart from './WidgetValues/Chart/RefreshChart';

import StartTimer from './WidgetValues/Timer/startTimer';
import StopTimer from './WidgetValues/Timer/stopTimer';
import ResetTimer from './WidgetValues/Timer/resetTimer';
import GetTimerValue from './WidgetValues/Timer/getTimer';

import RepeaterNavigateFirst from './Repeater/RepeaterNavigateFirst';
import RepeaterNavigateBack from './Repeater/RepeaterNavigateBack';
import RepeaterNavigateNext from './Repeater/RepeaterNavigateNext';
import RepeaterNavigateLast from './Repeater/RepeaterNavigateLast';
import RepeaterNavigateTo from './Repeater/RepeaterNavigateTo';
import RepeaterEntryNew from './Repeater/RepeaterEntryNew';
import RepeaterEntryDelete from './Repeater/RepeaterEntryDelete';
import RepeaterEntrySave from './Repeater/RepeaterEntrySave';
import RepeaterEntriesAmount from './Repeater/RepeaterEntriesAmount';
import RepeaterEntryIndex from './Repeater/RepeaterEntryIndex';
import RepeaterEntryValue from './Repeater/RepeaterEntryValue';
import RepeaterEntryIndexValue from './Repeater/RepeaterEntryIndexValue';

import BusinessCard from "./WidgetValues/BusinessCard";
import GetBusinessCardValue from './WidgetValues/BusinessCard/GetBusinessCardValue';
import SetBusinesCardValue from "./WidgetValues/BusinessCard/SetBusinessCardValue";
import ClearDateValue from './WidgetValues/DatePicker/ClearDateValue';
import Charts from './WidgetValues/Chart';
import CloseApp from './AppActions/CloseApp';


export default class ToolboxList {
    constructor({ context, libraries }) {
        return this._getToolboxList(context, libraries);
    }

    _getSearchFields(widgetsArr) {
        return _.chain(widgetsArr).flatten().reject(item => !_.isString(item)).value();
    }

    _getLibraryBlocks(library) {
        if (!library.blocks) { return {} }
        return library.blocks.reduce((allBlocks, block) => {
            return {
                [block.name]: {
                    component: block,
                    searchTags: [...block.keywords.split(","), block.name]
                }
            }
        }, {})
    }

    _getToolboxList(context, libraries) {
        const pages = this._getSearchFields(getAllPagesFromContext(context));
        const barcodeWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "barcode"));
        const buttonWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "button"));
        const checkboxWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "checkbox"));
        const checkboxListWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "checkboxlist"));
        const chartWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "chart"));
        const timerWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "timer"));
        const dateWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "date"));
        const dropdownWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "dropdown"));
        const labelWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "label"));
        const layoutWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "layout"));
        // const listWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "list"));
        const paragraphWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "paragraph"));
        const ratingWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "rating"));
        const radioListWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "radiobuttonlist"));
        const radioWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "radiobutton"));
        const sectionWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "section"));
        const imageWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "image"));
        const fileUploadWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "fileupload"));
        const textboxWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "textbox"));
        const tableWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "tableview"));
        const flipSwitchWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "flipswitch"));
        const mapWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "map"));
        const userListWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "userlist"));
        const businessCardWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "businesscard"));
        const groupListWidgets = this._getSearchFields(getAllTypeWidgetsFromContext(context, "grouplist"));
        const envVariables = this._getSearchFields(getAllVariablesFromContext(context));
        const xSightTopics = this._getSearchFields(getAllXSightTopicsFromContext(context));

        return {
            "Widget Values": {
                'barcode': {
                    component: Barcode,
                    searchTags: [],
                    childComponents: {
                        'GetScannedValues': {
                            component: GetScannedValues,
                            searchTags: ["barcode", "scan", "scanner", "scanned", "values", "get", "list", "widget", ...barcodeWidgets]
                        }
                    }
                },
                'button': {
                    component: Button,
                    searchTags: [],
                    childComponents: {
                        'SetButtonDisplayText': {
                            component: SetButtonDisplayText,
                            searchTags: ["set", "button", "display", "text", "widget", "to", "values", ...buttonWidgets]
                        }
                    }
                },
                "chart": {
                    component: Charts,
                    searchTags: [],
                    childComponents: {
                        RefreshChart: {
                            component: RefreshChart,
                            searchTags: ["chart", "refresh", "change"]
                        }
                    }
                },
                "timer": {
                    component: Timer,
                    searchTags: [],
                    childComponents: {
                        StartTimer: {
                            component: StartTimer,
                            searchTags: ["timer", "start", "change", "widget", "values", ...timerWidgets]
                        },
                        StopTimer: {
                            component: StopTimer,
                            searchTags: ["timer", "stop", "change", "widget", "values", ...timerWidgets]
                        },
                        ResetTimer: {
                            component: ResetTimer,
                            searchTags: ["timer", "reset", "change", "reset", "widget", "values", ...timerWidgets]
                        },
                        GetTimerValue: {
                            component: GetTimerValue,
                            searchTags: ["timer", "get", "value", "seconds", "format", "widget", "values", ...timerWidgets]
                        },
                    }
                },
                'checkboxlist': {
                    component: CheckboxList,
                    searchTags: [],
                    childComponents: {
                        SetCheckboxlistOption: {
                            component: SetCheckboxlistOption,
                            searchTags: ["set", "checkbox", "list", "option", "in", "to", "with", "text", "value", "widget", "values", ...checkboxListWidgets, ...checkboxWidgets]
                        },
                        InCheckboxlistCheckboxIsChecked: {
                            component: InCheckboxlistCheckboxIsChecked,
                            searchTags: ["in", "checkbox", "list", "is", "checked", "widget", "values", ...checkboxListWidgets, ...checkboxWidgets]
                        },
                        GetCheckboxlistSelectedCheckboxes: {
                            component: GetCheckboxlistSelectedCheckboxes,
                            searchTags: ["get", "checkbox", "list", "selected", "values", "text", "and", "csv", "checkboxes", "widget", "values", "of", "checked", "options", "in", "as", ...checkboxListWidgets]
                        }
                    }
                },
                'date': {
                    component: Dates,
                    searchTags: [],
                    childComponents: {
                        GetDateValue: {
                            component: GetDateValue,
                            searchTags: ["get", "date", "value", "widget", "values", ...dateWidgets]
                        },
                        SetDateValue: {
                            component: SetDateValue,
                            searchTags: ["set", "date", "value", "widget", "values", "to", ...dateWidgets]
                        },
                        ClearDateValue: {
                            component: ClearDateValue,
                            searchTags: ["clear", "date", "reset", "values", "to", ...dateWidgets]
                        },
                        SetDatePlaceholderText: {
                            component: SetDatePlaceholderText,
                            searchTags: ["set", "date", "placeholder", "text", "widget", "values", "to", ...dateWidgets]
                        }
                    }
                },
                'dropdown': {
                    component: Dropdown,
                    searchTags: [],
                    childComponents: {
                        AddDropdownOption: {
                            component: AddDropdownOption,
                            searchTags: ["add", "dropdown", "option", "text", "value", "to", "widget", "values", ...dropdownWidgets]
                        },
                        SetDropdown: {
                            component: SetDropdown,
                            searchTags: ["set", "dropdown", "option", "text", "value", "to", "by", "list", "widget", "values", ...dropdownWidgets]
                        },
                        GetSelectedValueOfDropdown: {
                            component: GetSelectedValueOfDropdown,
                            searchTags: ["get", "value", "text", "of", "selected", "dropdown", "option", "in", "widget", "values", ...dropdownWidgets]
                        },
                        DropdownIsSetTo: {
                            component: DropdownIsSetTo,
                            searchTags: ["dropdown", "option", "is", "set", "to", "widget", "values", ...dropdownWidgets]
                        }
                    }
                },
                'grouplist': {
                    component: GroupList,
                    searchTags: [],
                    childComponents: {
                        GetGroupList: {
                            component: GetGroupList,
                            searchTags: ["get", "group", "list", "selected", "value", ...groupListWidgets]
                        }
                    }
                },
                'label': {
                    component: Label,
                    searchTags: [],
                    childComponents: {
                        SetLabelText: {
                            component: SetLabelText,
                            searchTags: ['set', 'label', 'text', 'widget', 'values', "to", ...labelWidgets]
                        },
                        GetLabelText: {
                            component: GetLabelText,
                            searchTags: ['get', 'label', 'text', 'widget', 'values', ...labelWidgets]
                        }
                    }
                },
                'layout': {
                    component: Layout,
                    searchTags: [],
                    childComponents: {
                        SetLayoutBackgroundColour: {
                            component: SetLayoutBackgroundColour,
                            searchTags: ["set", "layout", "background", "colour", "color", "to", "widget", "values", ...layoutWidgets]
                        }
                    }
                },
                'map': {
                    component: Map,
                    searchTags: [],
                    childComponents: {
                        AddMarker: {
                            component: AddMarker,
                            searchTags: ["add", "map", "marker", "latitude", "longitude", "widget", "values", ...mapWidgets]
                        },
                        RemoveMarker: {
                            component: RemoveMarker,
                            searchTags: ["remove", "map", "marker", "widget", "values", ...mapWidgets]
                        },
                        SetMapZoom: {
                            component: SetMapZoom,
                            searchTags: ["set", "map", "zoom", "widget", "values", ...mapWidgets]
                        }
                    }
                },
                // TODO:: @sahanarula add list blocks in the next iteration
                // 'list': {
                //     component: List,
                //     searchTags: [],
                //     childComponents: {
                //         InListGetColumn: {
                //             component: InListGetColumn,
                //             searchTags: ['in', 'list', 'get', 'column', 'table', 'array', 'sequence', "widget", "values", ...listWidgets]
                //         },
                //         GetList: {
                //             component: GetList,
                //             searchTags: ['list', 'get', 'table', 'array', 'sequence', "widget", "values", ...listWidgets]
                //         },
                //     }
                // },
                'paragraph': {
                    component: Paragraph,
                    searchTags: [],
                    childComponents: {
                        SetParagraphText: {
                            component: SetParagraphText,
                            searchTags: ['set', 'paragraph', 'text', 'widget', 'values', 'to', ...paragraphWidgets]
                        },
                        GetParagraphText: {
                            component: GetParagraphText,
                            searchTags: ['get', 'paragraph', 'text', 'widget', 'values', ...paragraphWidgets]
                        },
                        SetParagraphColour: {
                            component: SetParagraphColour,
                            searchTags: ['set', 'paragraph', 'text', 'colour', 'widget', 'values', 'to', ...paragraphWidgets]
                        }
                    }
                },
                'rating': {
                    component: Rating,
                    searchTags: [],
                    childComponents: {
                        SetRating: {
                            component: SetRating,
                            searchTags: ["set", "rating", "to", "widget", "values", ...ratingWidgets]
                        },
                        GetRating: {
                            component: GetRating,
                            searchTags: ["get", "rating", "widget", "values", ...ratingWidgets]
                        }
                    }
                },
                'radiobuttonlist': {
                    component: RadioButtonList,
                    searchTags: [],
                    childComponents: {
                        SetRadiobuttonList: {
                            component: SetRadiobuttonList,
                            searchTags: ["set", "radio", "button", "text", "value", "to", "by", "text", "list", "widget", "values", ...radioWidgets, ...radioListWidgets]
                        },
                        GetValueOfSelectedRadiobutton: {
                            component: GetValueOfSelectedRadiobutton,
                            searchTags: ["get", "value", "text", "of", "selected", "radio", "button", "in", "widget", "values", ...radioWidgets, ...radioListWidgets]
                        },
                        RadiobuttonListIsSetTo: {
                            component: RadiobuttonListIsSetTo,
                            searchTags: ["radio", "button", "list", "is", "set", "to", "widget", "values", ...radioWidgets, ...radioListWidgets]
                        }
                    }
                },
                'section': {
                    component: Section,
                    searchTags: [],
                    childComponents: {
                        SetSectionBackgroundColour: {
                            component: SetSectionBackgroundColour,
                            searchTags: ["set", "section", "background", "colour", "color", "widget", "values", "to", ...sectionWidgets]
                        }
                    }
                },
                'image': {
                    component: Image,
                    searchTags: [],
                    childComponents: {
                        GetImageSource: {
                            component: GetImageSource,
                            searchTags: ["get", "image", "picture", "value", "src", "source", "url", "base64", "widget", "values", ...imageWidgets]
                        },
                        SetImageSource: {
                            component: SetImageSource,
                            searchTags: ["set", "image", "picture", "value", "src", "source", "url", "base64", "to", "widget", "values", ...imageWidgets]
                        },
                    }
                },
                'fileupload': {
                    component: FileUpload,
                    searchTags: [],
                    childComponents: {
                        GetUploadedValue: {
                            component: GetUploadedValue,
                            searchTags: ["get", "upload", "uploaded", "value", "file", "src", "source", "widget", "values", "single", ...fileUploadWidgets]
                        },
                        GetUploadedValues: {
                            component: GetUploadedValues,
                            searchTags: ["get", "upload", "uploaded", "value", "file", "src", "source", "widget", "values", "multiple", ...fileUploadWidgets]
                        },
                        ClearFileUpload: {
                            component: ClearFileUpload,
                            searchTags: ["clear", "remove", "delete", "reset", "upload", "uploaded", "value", "file", "src", "source", "widget", "values", ...fileUploadWidgets]
                        },
                    }
                },
                'textbox': {
                    component: Textbox,
                    searchTags: [],
                    childComponents: {
                        GetTextboxValue: {
                            component: GetTextboxValue,
                            searchTags: ["get", "textbox", "value", "widget", "values", ...textboxWidgets]
                        },
                        SetTextboxValue: {
                            component: SetTextboxValue,
                            searchTags: ["set", "textbox", "value", "to", "widget", "values", ...textboxWidgets]
                        },
                        SetTextboxColour: {
                            component: SetTextboxColour,
                            searchTags: ["set", "textbox", "colour", "background", "text", "color", "to", "widget", "values", ...textboxWidgets]
                        },
                        SetTextboxPlaceholderText: {
                            component: SetTextboxPlaceholderText,
                            searchTags: ["set", "textbox", "placeholder", "text", "to", "widget", "values", ...textboxWidgets]
                        }
                    }
                },
                'switch': {
                    component: FlipSwitch,
                    searchTags: [],
                    childComponents: {
                        GetFlipSwitchValue: {
                            component: GetFlipSwitchValue,
                            searchTags: ["get", "flipswitch", "switch", "value", "widget", "values", ...flipSwitchWidgets]
                        },
                        SetFlipSwitchValue: {
                            component: SetFlipSwitchValue,
                            searchTags: ["set", "flipswitch", "switch", "value", "widget", "values", ...flipSwitchWidgets]
                        }
                    }
                },
                'hamburger': {
                    component: Hamburger,
                    searchTags: [],
                    childComponents: {
                        GetHamburgerName: {
                            component: GetHamburgerName,
                            searchTags: ["get", "hamburger", "name", "widget", "values"]
                        },
                        SetHamburgerName: {
                            component: SetHamburgerName,
                            searchTags: ["set", "hamburger", "name", "widget", "values"]
                        },
                        GetHamburgerImage: {
                            component: GetHamburgerImage,
                            searchTags: ["get", "hamburger", "image", "widget", "values"]
                        },
                        SetHamburgerImage: {
                            component: SetHamburgerImage,
                            searchTags: ["set", "hamburger", "image", "widget", "values"]
                        }
                    }
                },
                'dynamicdropdown': {
                    component: ExternalData,
                    searchTags: [],
                    childComponents: {
                        GetExternalDataColumn: {
                            component: GetExternalDataColumn,
                            searchTags: ["get", "external", "data", "column", "value"]
                        }
                    }
                },
                'user': {
                    component: User,
                    searchTags: [],
                    childComponents: {
                        GetUserLoggedIn: {
                            component: GetUserLoggedIn,
                            searchTags: ["get", "user", "logged", "in", "value"]
                        },
                        GetUserField : {
                            component: GetUserField,
                            searchTags: ["get", "user", "value", "first", "last", "full", "name", "email", "system", "id"]
                        },
                        GetUserId: {
                            component: GetUserId,
                            searchTags: ["get", "user", "id", "value"]
                        },
                        GetUserFirstName: {
                            component: GetUserFirstName,
                            searchTags: ["get", "user", "first", "name", "value"]
                        },
                        GetUserLastName: {
                            component: GetUserLastName,
                            searchTags: ["get", "user", "last", "name", "value"]
                        },
                        GetUserFullName: {
                            component: GetUserFullName,
                            searchTags: ["get", "user", "full", "name", "value"]
                        },
                        GetUserEmail: {
                            component: GetUserEmail,
                            searchTags: ["get", "user", "email", "value"]
                        },
                        GetUserSystemId: {
                            component: GetUserSystemId,
                            searchTags: ["get", "user", "system", "id", "value"]
                        }
                    }
                },
                'userlist': {
                    component: UserList,
                    searchTags: [],
                    childComponents: {
                        GetUserList: {
                            component: GetUserList,
                            searchTags: ["get", "user", "list", "selected", "value", ...userListWidgets]
                        }
                    }
                },
                'businesscard': {
                    component: BusinessCard,
                    searchTag: [],
                    childComponents: {
                        GetBusinessCardValue: {
                            component: GetBusinessCardValue,
                            searchTags: ["get", "business", "businesscard" , "BusinessCard", ...businessCardWidgets]
                        },
                        SetBusinesCardValue: {
                            component: SetBusinesCardValue,
                            searchTags: ["set", "business", "businesscard" , "BusinessCard", ...businessCardWidgets]
                        }
                    }

                },
                // 'checkbox': {
                //     component: Checkbox,
                //     searchTags: []
                // },
                // 'dropdown': {
                //     component: DropdownIsSetTo,
                //     searchTags: []
                // },
            },
            "App Actions": {
                CloseApp: {
                    component: CloseApp,
                    searchTags: ["close", "quit", "app", "exit", "actions"]
                },
                SnapActionNavigate: {
                    component: SnapActionNavigate,
                    searchTags: ["navigate", "page", "screen", "app", "actions", ...pages]
                },
                SnapActionNavigateBack: {
                    component: SnapActionNavigateBack,
                    searchTags: ["navigate", "back", "app", "actions", "previous", "redirect"]
                },
                SnapActionResetPage: {
                    component: SnapActionResetPage,
                    searchTags: ["reset", "page", "form", "screen", "fields", "unset", "empty", "app", "actions"]
                },
                SnapActionSubmit: {
                    component: SnapActionSubmit,
                    searchTags: ["submit", "form", "fields", "save", "app", "actions"]
                },
                SnapActionSync: {
                    component: SnapActionSync,
                    searchTags: ["sync", "form", "fields", "save", "app", "actions"]
                },
                SnapActionValidate: {
                    component: SnapActionValidate,
                    searchTags: ["page", "form", "fields", "validate", "app", "actions", "required"]
                },
                SnapActionOpenBrowser: {
                    component: SnapActionOpenBrowser,
                    searchTags: ["navigate", "open", "browser", "url", "web", "internet", "os", "surf", "query", "string"]
                },
                SetFocus: {
                    component: SetFocus,
                    searchTags: ["page", "form", "set", "focus", "in", "actions"]
                },
                ReloadCurrentPage: {
                    component: ReloadCurrentPage,
                    searchTags: ["reload", "refresh", "current", "page", "load", "onload", "event", "trigger", "screen", "app", "action"]
                },
                ReloadPage: {
                    component: ReloadPage,
                    searchTags: ["reload", "refresh", "page", "load", "onload", "event", "trigger", "screen", "app", "action"]
                },
                OpenApp: {
                    component: OpenApp,
                    searchTags: ["open", "app" , "action"]
                },
                OpenSnapApp: {
                    component: OpenSnapApp,
                    searchTags: ["open", "app" , "action" , "snap"]
                }
            },
            // TODO:: @sahanarula: add new implementation with api server
            "Connections": {
                // SimpleRestService: {
                //     component: SimpleRestService,
                //     searchTags: ["simple", "rest", "service", "get", "api", "connections"]
                // },
                RestService: {
                    component: RestService,
                    searchTags: ["simple", "rest", "service", "get", "put", "post", "delete", "headers", "api", "connections"]
                },
            },
            "Global Variables": {
                SetEnvironmentVariable: {
                    component: SetEnvironmentVariable,
                    searchTags: ["set", "environment", "variable", "to", "import", "export", "data", "external data", ...envVariables]
                },
                GetEnvironmentVariable: {
                    component: GetEnvironmentVariable,
                    searchTags: ["get", "environment", "variable", "import", "export", "data", "external data", ...envVariables]
                }
            },
            "XSight Topics": {
                SetXSightTopic: {
                    component: SetXSightTopic,
                    searchTags: ["set", "xsight", "topic", ...xSightTopics]
                },
                GetXSightTopic: {
                    component: GetXSightTopic,
                    searchTags: ["get", "xsight", "topic", ...xSightTopics]
                }
            },
            "Logic": {
                ControlsIf: {
                    component: ControlsIf,
                    searchTags: ["if", "compare", "logic", "comparison", "greater", "lesser", "equals", "this", "then", "that", "else", "do"]
                },
                LogicBoolean: {
                    component: LogicBoolean,
                    searchTags: ["compare", "logic", "comparison", "boolean", "bool", "1", "0", "true", "false"]
                },
                // LogicCompare: {
                //     component: LogicCompare,
                //     searchTags: ["compare", "logic", "comparison", "greater", "lesser", "equals", "not"]
                // },
                LogicCompareNew: {
                    component: LogicCompareNew,
                    searchTags: ["compare", "logic", "comparison", "greater", "lesser", "equals", "not"]
                },
                LogicNegate: {
                    component: LogicNegate,
                    searchTags: ["negate", "negative", "opposite", "flip", "rather", "otherwise", "not", "logic"]
                },
                LogicNull: {
                    component: LogicNull,
                    searchTags: ["logic", "null", "not", "empty", "undefined"]
                },
                LogicOperation: {
                    component: LogicOperation,
                    searchTags: ["boolean", "and", "or", "logic", "gates", "logic"]
                },
                SnapWait: {
                    component: SnapWait,
                    searchTags: ["wait", "hold", "delay", "pause", "after", "time", "later", "logic", "for", "then", "do"]
                },
            },
            "Loops": {
                ControlsRepeat: {
                    component: ControlsRepeat,
                    searchTags: ["repeat", "times", "for", "loops", "do", "iterate"]
                },
                ControlsWhileUntil: {
                    component: ControlsWhileUntil,
                    searchTags: ["repeat", "while", "until", "for", "do", "loops"]
                },
                CountWith: {
                    component: CountWith,
                    searchTags: ["count", "for", "loops", "numbers", "increment", "iterate", "with", "from", "by", "to", "until"]
                },
                ForEachItemInList: {
                    component: ForEachItemInList,
                    searchTags: ["for", "each", "item", "in", "iterate", "loops", "repeat", "list", "do"]
                },
                ControlsFlowStatements: {
                    component: ControlsFlowStatements,
                    searchTags: ["loops", "controls", "flow", "break", "exit", "out", "of", "continue", "with", "next", "iteration"]
                },
            },
            "Math": {
                MathNumber: {
                    component: MathNumber,
                    searchTags: ["math", "numbers", "1234567890"]
                },
                MathArithmetic: {
                    component: MathArithmetic,
                    searchTags: ["math", "arithmetic", "plus", "minus", "divide", "add", "subtract", "times", "multiply", "power", "raise", "exponent", "by", "over", "1234567890", "sum", "+", "-", "/", "*", "x", "^"]
                },
                MathSingle: {
                    component: MathSingle,
                    searchTags: ["math", "square", "root", "absolute", "negative", "-", "ln", "log", "exponent", "e", "^", "10", "sqrt", "1234567890"]
                },
                MathTrig: {
                    component: MathTrig,
                    searchTags: ["math", "trignometry", "sin", "cos", "tan", "asin", "acos", "atan", "1234567890"]
                },
                MathConstant: {
                    component: MathConstant,
                    searchTags: ["math", "constants", "sqrt", "pi", "epsilon", "phi", "square", "root", "infinity", "1234567890"]
                },
                MathNumberProperty: {
                    component: MathNumberProperty,
                    searchTags: ["math", "constant", "1234567890", "even", "odd", "prime", "whole", "positive", "negative", "divisible", "by", "is"]
                },
                MathToNumber: {
                    component: MathToNumber,
                    searchTags: ["math", "convert", "to", "number", "decimal", "decimals", "floating", "numbers", "1234567890"]
                },
                MathRound: {
                    component: MathRound,
                    searchTags: ["math", "round", "decimals", "floating", "numbers", "1234567890"]
                },
                MathOnList: {
                    component: MathOnList,
                    searchTags: ["math", "list", "sum", "min", "max", "average", "median", "modes", "sd", "standard", "deviation", "random", "item", "of"]
                },
                MathModulo: {
                    component: MathModulo,
                    searchTags: ["math", "modulo", "remainder", "1234567890", "/", "divided", "divisible", "of", "%"]
                },
                MathConstrain: {
                    component: MathConstrain,
                    searchTags: ["math", "constrain", "low", "high", "min", "max", "1234567890", "clip", "range"]
                },
                MathRandomInt: {
                    component: MathRandomInt,
                    searchTags: ["math", "random", "int", "low", "high", "min", "max", "1234567890", "range", "integer", "from", "to"]
                },
                MathRandomFloat: {
                    component: MathRandomFloat,
                    searchTags: ["math", "random", "float", "fraction", "decimal", "1234567890", ".", "numbers", "floating"]
                },
                RoundToDecimal: {
                    component: RoundToDecimal,
                    searchTags: ["math", "round", "to", "decimal", "round", "decimals", "floating", "numbers", "1234567890", "up", "down", "ceiling", "truncate", "floor"]
                }
            },
            "Repeater": {
                RepeaterNavigateFirst: {
                    component: RepeaterNavigateFirst,
                    searchTags: ["repeater", "navigate", "navigation", "first", "entry", "record", "item"]
                },
                RepeaterNavigateBack: {
                    component: RepeaterNavigateBack,
                    searchTags: ["repeater", "navigate", "navigation", "back", "prev", "previous", "entry", "record", "item"]
                },
                RepeaterNavigateNext: {
                    component: RepeaterNavigateNext,
                    searchTags: ["repeater", "navigate", "navigation", "forward", "next", "entry", "record", "item"]
                },
                RepeaterNavigateLast: {
                    component: RepeaterNavigateLast,
                    searchTags: ["repeater", "navigate", "navigation", "last", "entry", "record", "item"]
                },
                RepeaterNavigateTo: {
                    component: RepeaterNavigateTo,
                    searchTags: ["repeater", "navigate", "navigation", "to", "index", "page", "number", "entry", "record", "item"]
                },
                RepeaterEntryNew: {
                    component: RepeaterEntryNew,
                    searchTags: ["repeater", "create", "new", "empty", "entry", "record", "item"]
                },
                RepeaterEntryDelete: {
                    component: RepeaterEntryDelete,
                    searchTags: ["repeater", "delete", "remove", "confirm", "confirmation", "entry", "record", "item"]
                },
                RepeaterEntrySave: {
                    component: RepeaterEntrySave,
                    searchTags: ["repeater", "save", "add", "update", "validate", "submit", "entry", "record", "item"]
                },
                RepeaterEntriesAmount: {
                    component: RepeaterEntriesAmount,
                    searchTags: ["repeater", "get", "amount", "count", "length", "entries", "records", "items", "entry", "record", "item"]
                },
                RepeaterEntryIndex: {
                    component: RepeaterEntryIndex,
                    searchTags: ["repeater", "get", "index", "page", "current", "number", "position", "entry", "record", "item"]
                },
                RepeaterEntryValue: {
                    component: RepeaterEntryValue,
                    searchTags: ["repeater", "get", "widget", "value", "state", "current", "entry", "record", "item"]
                },
                RepeaterEntryIndexValue: {
                    component: RepeaterEntryIndexValue,
                    searchTags: ["repeater", "get", "widget", "value", "state", "index", "page", "position", "entry", "record", "item"]
                },
            },
            "Table": {
                SelectedRow: {
                    component: SelectedRow,
                    searchTags: ["Table", "TableView", "View", "selected", "get", "row", "data", "table", "column"]
                },
                AddRow: {
                    component: AddRow,
                    searchTags: ["Table", "TableView", "View", "add", "create", "row", "data", "table"]
                },        
                GetRecords: {
                    component: GetRecords,
                    searchTags: ["Table", "TableView", "View", "get", "records", "table"]
                },
                SetColumnName: {
                    component: SetColumnName,
                    searchTags: ["Table", "TableView", "View", "set", "column", "name", "rename", "table"]
                },
                GetColumn: {
                    component: GetColumn,
                    searchTags: ["Table", "TableView", "View", "get", "column", "records", "data", "table"]
                },
                DeleteRow: {
                    component: DeleteRow,
                    searchTags: ["Table", "TableView", "View", "delete", "row", "table"]
                },
                UpdateRow: {
                    component: UpdateRow,
                    searchTags: ["Table", "TableView", "View", "update", "value", "change", "edit", "row", "table"]
                },
                RefreshTable: {
                    component: RefreshTable,
                    searchTags: ["Table", "TableView", "View", "refresh", "table"]
                },
                RunEditRecords: {
                    component: RunEditRecords,
                    searchTags: ["Table", "TableView", "View", "run", "edit", "records"]
                },
                CancelEditRecords: {
                    component: CancelEditRecords,
                    searchTags: ["Table", "TableView", "View", "cancel", "edit", "records"]
                },
                ShowHideRow: {
                    component: ShowHideRow,
                    searchTags: ["Table", "TableView", "View", "show", "hide", "row", "table"]
                },
                LoadTableDataRest: {
                    component: LoadTableDataRest,
                    searchTags: ["Table", "TableView", "View", "get", "set", "post", "rest", "url", "response", "records", "data", "table"]
                }
            },
            "Sound": {
                PlayNote: {
                    component: PlayNote,
                    searchTags: ["sound", "music", "play", "volume", "note"]
                },
                PlayBeep: {
                    component: PlayBeep,
                    searchTags: ["sound", "music", "play", "beep", "volume", "seconds"]
                }
            },
            "MobiControl": {
                MobiControlGetAndroidDeviceDetails: {
                    component: MobiControlGetAndroidDeviceDetails,
                    searchTags: ["mobicontrol", "mc", "device", "get", "android", "details", "custom", "attribute", "category", "group", "key", "value", "hardware", "configuration"]
                },
                MobiControlGetCustomAttribute: {
                    component: MobiControlGetCustomAttribute,
                    searchTags: ["mobicontrol", "mc", "device", "get", "custom", "attribute", "key", "value",  "hardware", "configuration"]
                },
                MobiControlGetIsEnrolled: {
                    component: MobiControlGetIsEnrolled,
                    searchTags: ["mobicontrol", "mc", "device", "get", "is", "enrolled", "network", "value", "configuration"]
                },
                MobiControlGetDeviceId: {
                    component: MobiControlGetDeviceId,
                    searchTags: ["mobicontrol", "mc", "device", "get", "access", "id", "identification", "hardware", "value", "configuration"]
                },
                MobiControlGetIpAddress: {
                    component: MobiControlGetIpAddress,
                    searchTags: ["mobicontrol", "mc", "device", "get", "access", "ip", "address", "hardware", "network", "configuration"]
                },
                MobiControlGetMacAddress: {
                    component: MobiControlGetMacAddress,
                    searchTags: ["mobicontrol", "mc", "device", "get", "access", "mac", "address", "hardware", "network", "value", "configuration"]
                },
                MobiControlGetSerialNumber: {
                    component: MobiControlGetSerialNumber,
                    searchTags: ["mobicontrol", "mc", "device", "get", "access", "serial", "number", "hardware", "value", "configuration"]
                },
                MobiControlGetIosDeviceDetails: {
                    component: MobiControlGetIosDeviceDetails,
                    searchTags: ["mobicontrol", "mc", "device", "get", "ios", "apple", "details", "custom", "attribute", "key", "value",  "hardware", "configuration"]
                },
            },
            "Text": {
                Text: {
                    component: Text,
                    searchTags: ["text", "string", "value", "placeholder"]
                },
                TextJoin: {
                    component: TextJoin,
                    searchTags: ["text", "string", "join", "create", "text", "with", "add", "remove", "subtract"]
                },
                TextAppend: {
                    component: TextAppend,
                    searchTags: ["to", "string", "item", "append", "text", "variable"]
                },
                TextLength: {
                    component: TextLength,
                    searchTags: ["text", "length", "string", "of"]
                },
                SOTISnackbar: {
                    component: SotiSnackbar,
                    searchTags: ['display', 'message', 'print', 'show', 'text', 'toast', 'snackbar', 'alert', 'prompt', 'error', "duration", "color", "colour", "background", "with", "animation", "fade", "in", "slide", "down", "up", "right", "to", "left"]
                },
                ShowMessage: {
                    component: ShowMessage,
                    searchTags: ['show', 'message', 'print', 'show', 'text', 'toast', 'alert', 'prompt', 'error', "duration", "color", "colour", "background", "with", "animation", "fade", "in", "slide", "down", "up", "right", "to", "left"]
                },
                ShowPrompt: {
                    component: ShowPrompt,
                    searchTags: ['show', 'message', 'print', 'show', 'text', 'toast', 'alert', 'prompt', 'error']
                },
                TextIsEmpty: {
                    component: TextIsEmpty,
                    searchTags: ["text", "string", "value", "is", "empty"]
                },
                Confirm: {
                    component: Confirm,
                    searchTags: ["confirmation", "message", "popup", "confirm", "text", "authentication", "alert", 'approval']
                },
                TextRegex: {
                    component: TextRegex,
                    searchTags: ["text", "string", "value", "is", "regular", "expresion", "regex", "validate", "test", "match", "against"]
                },
                TextIndexOf: {
                    component: TextIndexOf,
                    searchTags: ["text", "string", "index", "of", "in", "find", "first", "last", "#", "occurrence", "value"]
                },
                TextCharAt: {
                    component: TextCharAt,
                    searchTags: ["text", "in", "string", "get", "index", "first", "last", "#", "from", "end", "random", "letter"]
                },
                TextGetSubstring: {
                    component: TextGetSubstring,
                    searchTags: ["text", "in", "string", "get", "index", "first", "last", "#", "from", "end", "random", "letter", "substring", "to"]
                },
                TextChangeCase: {
                    component: TextChangeCase,
                    searchTags: ["text", "string", "to", "upper", "case", "lower", "title", "uppercase", "lowercase", "titlecase", "value"]
                },
                TextTrim: {
                    component: TextTrim,
                    searchTags: ["text", "string", "trim", "spaces", "from", "both", "left", "right", "sides", "in", "of", "value", "padding"]
                }
            },
            "Lists": {
                ListsCreateWith: {
                    component: ListsCreateWith,
                    searchTags: ["create", "lists", "with", "()", "[]", "array", "sequence", "dictionary"]
                },
                ListsRepeat: {
                    component: ListsRepeat,
                    searchTags: ["repeat", "create", "lists", "with", "item", "()", "[]", "array", "sequence", "dictionary"]
                },
                ListsLength: {
                    component: ListsLength,
                    searchTags: ["lists", "with", "items", "array", "sequence", "dictionary", "length", "count", "of"]
                },
                ListsIsEmpty: {
                    component: ListsIsEmpty,
                    searchTags: ["lists", "with", "items", "array", "sequence", "dictionary", "count", "empty", "none", "null", "nothing", "undefined", "is"]
                },
                ListsIndexOf: {
                    component: ListsIndexOf,
                    searchTags: ["lists", "with", "items", "array", "sequence", "dictionary", "first", "last", "occurrence", "exists", "has", "contains", "includes", "find", "first", "last", "occurence", "of", "item"]
                },
                ListsGetIndex: {
                    component: ListsGetIndex,
                    searchTags: ["lists", "with", "items", "array", "sequence", "dictionary", "first", "last", "occurrence", "1234567890", "index", "position", "at", "remove", "delete", "get", "#", "random", "from", "end", "start", "get", "and", "remove", "first", "end", "random", "hash"]
                },
                ListsSetIndex2: {
                    component: ListsSetIndex2,
                    searchTags: ["lists", "with", "items", "array", "sequence", "dictionary", "first", "last", "occurrence", "1234567890", "index", "position", "at", "#", "random", "from", "end", "start", "set", "insert", "variables", "as"]
                },
                ListsGetSublist: {
                    component: ListsGetSublist,
                    searchTags: ["lists", "with", "items", "array", "sequence", "dictionary", "1234567890", "index", "position", "at", "#", "random", "from", "end", "start", "index", "portion", "sub", "partial", "get", "sub", "list", "from", "to", "end", "from", "first", "last"]
                },
                ListsSplit: {
                    component: ListsSplit,
                    searchTags: ["lists", "with", "items", "array", "sequence", "dictionary", "string", "text", "delimiter", "make", "text", "from", "split", ",-+={}|/;'!@#$%^&*()_+=-1234567890<>?:[]~"]
                },
                ListsSort: {
                    component: ListsSort,
                    searchTags: ["lists", "with", "items", "array", "sequence", "dictionary", "sort", "ascending", "descending", "numeric", "arrange", "alphabetic", "ignore", "case", "numeric"]
                },
                MakeJSONWith: {
                    component: MakeJSONWith,
                    searchTags: ["lists", "with", "array", "sequence", "dictionary", "json", "objects", "parse", "{}[]", "key", "value", "create", "make"]
                },
                GetFromJSON: {
                    component: GetFromJSON,
                    searchTags: ["lists", "with", "array", "sequence", "dictionary", "json", "objects", "parse", "{}[]", "key", "value", "get", "from"]
                },
                UpdateJSON: {
                    component: UpdateJSON,
                    searchTags: ["lists", "with", "array", "sequence", "dictionary", "json", "objects", "parse", "{}[]", "key", "value", "set", "to", "update"]
                }
            },
            "Colour": {
                ColourPicker: {
                    component: ColourPicker,
                    searchTags: ["colour", "color", "red", "blue", "green", "picker", "colors", "colours", "palette"]
                },
                ColourRGB: {
                    component: ColourRGB,
                    searchTags: ["colour", "color", "red", "blue", "green", "rgb", "colors", "colours", "hex"]
                }
            },
            "Dates": {
                DateBlock: {
                    component: DateBlock,
                    searchTags: ["dates", "date", "picker", "datepicker"]
                },
                Today: {
                    component: Today,
                    searchTags: ["dates", "date", "today"]
                },
                GetDayFromDate: {
                    component: GetDayFromDate,
                    searchTags: ["get", "dates", "date", "day", "month", "year", "from", "picker", "datepicker"]
                },
                AddToDate: {
                    component: AddToDate,
                    searchTags: ["dates", "date", "days", "months", "years", "add", "to", "subtract", "1234567890"]
                },
                DatePickerNumber: {
                    component: DatePickerNumber,
                    searchTags: ["dates", "date", "create", "day", "month", "year"]
                },
                DateIsBefore: {
                    component: DateIsBefore,
                    searchTags: ["dates", "date", "is", "before", "after", "same", "date", "as", "datepicker", "picker", "comparison", "compare"]
                }
            },
            "Debug Tools": {
                Log: {
                    component: Log,
                    searchTags: ["log", "debug", "tools"]
                },
                LogInfo: {
                    component: LogInfo,
                    searchTags: ["log", "info", "debug", "tools"]
                },
                LogError: {
                    component: LogError,
                    searchTags: ["log", "error", "debug", "tools"]
                }
                /*TryCatch: {
                    component: TryCatch,
                    searchTags: ["debug", "tools", "try", "catch", "execute", "fail", "success", "error", "handle"]
                }*/
            },
            "My Libraries": {
                ...libraries.reduce((allLibraries, library) => {
                    return {
                        ...allLibraries,
                        [library.name]: {
                            childComponents: {
                                ...this._getLibraryBlocks(library)
                            }
                        }
                    }
                }, {})
            }
        }
    }
}
