/* global Blockly */
import React from 'react';

function defineBlock() {
    return {
        init: function () {
            this.appendDummyInput()
                .appendField("close app")
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.APP_ACTIONS_HUE);
            this.setTooltip('Close current app.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#app-actions");
        }
    }
}

function defineGenerators() {
    return {
        "JavaScript": function () {
            let code = `applicationContextPlugin.exitApplication();\n`;
            return code;
        }
    }
}

const CloseApp = function (props) {
    let name = "snap_action_closeapp";

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return <block type={name}></block>;
};

export default CloseApp
